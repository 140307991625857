import { Observable } from '../Observable';
import { Subscriber } from '../Subscriber';
import { noop } from '../util/noop';

/**
 * We need this JSDoc comment for affecting ESDoc.
 * @extends {Ignored}
 * @hide true
 */
export class NeverObservable<T> extends Observable<T> {
  /**
   * Creates an Observable that emits no items to the Observer.
   *
   * <span class="informal">An Observable that never emits anything.</span>
   *
   * <img src="./img/never.png" width="100%">
   *
   * This static operator is useful for creating a simple Observable that emits
   * neither values nor errors nor the completion notification. It can be used
   * for testing purposes or for composing with other Observables. Please note
   * that by never emitting a complete notification, this Observable keeps the
   * subscription from being disposed automatically. Subscriptions need to be
   * manually disposed.
   *
   * @example <caption>Emit the number 7, then never emit anything else (not even complete).</caption>
   * function info() {
   *   console.log('Will not be called');
   * }
   * var result = Rx.Observable.never().startWith(7);
   * result.subscribe(x => console.log(x), info, info);
   *
   * @see {@link create}
   * @see {@link empty}
   * @see {@link of}
   * @see {@link throw}
   *
   * @return {Observable} A "never" Observable: never emits anything.
   * @static true
   * @name never
   * @owner Observable
   */
  static create<T>() {
    return new NeverObservable<T>();
  }

  constructor() {
    super();
  }

  protected _subscribe(subscriber: Subscriber<T>): void {
    noop();
  }
}
